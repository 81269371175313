import { Language } from '@trophee-libs/uikit'

export const AR: Language = {
  locale: 'ar-SA',
  language: 'العربية',
  code: 'ar'
}
export const EN: Language = {
  locale: 'en-US',
  language: 'English',
  code: 'en'
}
export const DE: Language = {
  locale: 'de-DE',
  language: 'Deutsch',
  code: 'de'
}
export const EL: Language = {
  locale: 'el-GR',
  language: 'Ελληνικά',
  code: 'el'
}
export const ESES: Language = {
  locale: 'es-ES',
  language: 'Español',
  code: 'es-ES'
}
export const FI: Language = {
  locale: 'fi-FI',
  language: 'Suomalainen',
  code: 'fi'
}
export const FIL: Language = {
  locale: 'fil-PH',
  language: 'Filipino',
  code: 'fil'
}
export const FR: Language = {
  locale: 'fr-FR',
  language: 'Français',
  code: 'fr'
}
export const HI: Language = { locale: 'hi-IN', language: 'हिंदी', code: 'hi' }
export const HU: Language = { locale: 'hu-HU', language: 'Magyar', code: 'hu' }
export const ID: Language = {
  locale: 'id-ID',
  language: 'Bahasa Indonesia',
  code: 'id'
}
export const IT: Language = {
  locale: 'it-IT',
  language: 'Italiano',
  code: 'it'
}
export const JA: Language = { locale: 'ja-JP', language: '日本語', code: 'ja' }
export const KO: Language = { locale: 'ko-KR', language: '한국어', code: 'ko' }
export const NL: Language = {
  locale: 'nl-NL',
  language: 'Nederlands',
  code: 'nl'
}
export const PTBR: Language = {
  locale: 'pt-BR',
  language: 'Português (Brazil)',
  code: 'pt-br'
}
export const RO: Language = { locale: 'ro-RO', language: 'Română', code: 'ro' }
export const RU: Language = {
  locale: 'ru-RU',
  language: 'Русский',
  code: 'ru'
}
export const SVSE: Language = {
  locale: 'sv-SE',
  language: 'Svenska',
  code: 'sv'
}
export const TA: Language = { locale: 'ta-IN', language: 'தமிழ்', code: 'ta' }
export const TR: Language = { locale: 'tr-TR', language: 'Türkçe', code: 'tr' }
export const UK: Language = {
  locale: 'uk-UA',
  language: 'Українська',
  code: 'uk'
}
export const VI: Language = {
  locale: 'vi-VN',
  language: 'Tiếng Việt',
  code: 'vi'
}
export const ZHCN: Language = {
  locale: 'zh-CN',
  language: '简体中文',
  code: 'zh-cn'
}
export const ZHTW: Language = {
  locale: 'zh-TW',
  language: '繁體中文',
  code: 'zh-tw'
}

export const languages = {
  'en-US': EN,
  'vi-VN': VI
}

export const languageList = Object.values(languages)
