import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Trophee | Exchange'
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Trophee | Exchange'
  },
  '/farms': {
    title: 'Trophee | Farm'
  },
  '/pools': {
    title: 'Trophee | Pool'
  }
}
