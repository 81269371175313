import React, { useEffect, lazy } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ResetCSS } from '@trophee-libs/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'

import {
  useFetchPriceList,
  useFetchProfile,
  useFetchPublicData,
  useSaveReferrer
} from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
import history from './routerHistory'
import Swap from './views/Swap'
import { RedirectToSwap } from './views/Swap/redirects'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Farms = lazy(() => import('./views/Farms'))
const NotFound = lazy(() => import('./views/NotFound'))
const Bridge = lazy(() => import('./views/Bridge'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Referrals = lazy(() => import('views/Referrals'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
})

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useEagerConnect()
  useFetchPublicData()
  useFetchProfile()
  useFetchPriceList()
  useSaveReferrer()
  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path='/' exact>
              <Redirect to='/swap' />
            </Route>
            <Route exact strict path='/swap' component={Swap} />
            <Route path='/swap/:outputCurrency' component={RedirectToSwap} />
            <Route path='/farms'>
              <Farms />
            </Route>
            <Route path='/pools'>
              <Farms isPool={true} />
            </Route>
            <Route exact path='/bridge' component={Bridge} />
            {/* <Route path='/lottery'>
              <Lottery />
            </Route>
            <Route path='/launchpad'>
              <ITO />
            </Route> */}
            <Route path='/pool'>
              <Redirect to='/liquidity' />
            </Route>
            <Route exact strict path='/find' component={PoolFinder} />
            <Route exact strict path='/liquidity' component={Liquidity} />
            <Route
              exact
              strict
              path='/create'
              component={RedirectToAddLiquidity}
            />
            <Route exact path='/add' component={AddLiquidity} />
            <Route
              exact
              path='/add/:currencyIdA'
              component={RedirectOldAddLiquidityPathStructure}
            />
            <Route
              exact
              path='/add/:currencyIdA/:currencyIdB'
              component={RedirectDuplicateTokenIds}
            />
            <Route exact path='/create' component={AddLiquidity} />
            <Route
              exact
              path='/create/:currencyIdA'
              component={RedirectOldAddLiquidityPathStructure}
            />
            <Route
              exact
              path='/create/:currencyIdA/:currencyIdB'
              component={RedirectDuplicateTokenIds}
            />
            <Route
              exact
              strict
              path='/remove/:tokens'
              component={RedirectOldRemoveLiquidityPathStructure}
            />
            <Route
              exact
              strict
              path='/remove/:currencyIdA/:currencyIdB'
              component={RemoveLiquidity}
            />
            <Route path='/referrals'>
              <Referrals />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
