import { MenuEntry } from '@trophee-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Marketplace',
    icon: 'HomeIcon',
    href: 'https://www.trophee.xyz'
  },
  {
    label: 'Swap',
    icon: 'TradeIcon',
    href: '/swap'
  },
  {
    label: 'Liquidity',
    icon: 'LiquidityIcon',
    href: '/liquidity'
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms'
  }
  // {
  //   label: "Pools",
  //   icon: "PoolIcon",
  //   href: "/pools",
  // },
]

export default config
