import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@trophee-libs/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Rajdhani', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .section-banner {
    min-height: 160px;
    margin: 0 0 32px 0;
    padding: 52px 60px 0 200px;
    border-radius: 12px;
    background: url('/images/banner/banner-background.jpg') no-repeat center;
    position: relative;
  }
  
  .section-banner .section-banner-icon {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .section-banner .section-banner-title,
  .section-banner .section-banner-text {
    color: #fff;
  }
  
  .section-banner .section-banner-title {
    font-size: 2.25rem;
    font-weight: 700;
  }
  
  .section-banner .section-banner-text {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
  }

  .loader-bars {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    height: 40px;
  }
  
  .loader-bars .loader-bar {
    width: 4px;
    height: 100%;
    margin-right: 4px;
    border-radius: 200px;
    -webkit-transform: scaleY(0.2);
    transform: scaleY(0.2);
    -webkit-animation: loader-bars 0.5s infinite alternate ease-in;
    animation: loader-bars 0.5s infinite alternate ease-in;
  }
  
  .loader-bars .loader-bar:last-child {
    margin-right: 0;
  }
  
  .loader-bars .loader-bar:nth-child(1) {
    background-color: #615dfa;
  }
  
  .loader-bars .loader-bar:nth-child(2) {
    background-color: #5d71fb;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  
  .loader-bars .loader-bar:nth-child(3) {
    background-color: #5983fb;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  
  .loader-bars .loader-bar:nth-child(4) {
    background-color: #549afc;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  
  .loader-bars .loader-bar:nth-child(5) {
    background-color: #4eb2fd;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  
  .loader-bars .loader-bar:nth-child(6) {
    background-color: #49c9fe;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  
  .loader-bars .loader-bar:nth-child(7) {
    background-color: #45ddfe;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .loader-bars .loader-bar:nth-child(8) {
    background-color: #41efff;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  
  @-webkit-keyframes loader-bars {
    0% {
      -webkit-transform: scaleY(0.2);
      transform: scaleY(0.2);
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
  }
  
  @keyframes loader-bars {
    0% {
      -webkit-transform: scaleY(0.2);
      transform: scaleY(0.2);
    }
    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
    }
  }
`

export default GlobalStyle
