import tokens from './tokens'
import { FarmConfig } from './types'

const masterChefMANGA = {
  97: '0xBbf889E2A2Db5f3B671ef069F7EF3Adb6C4CCACD',
  56: '0x4b05E4A7667C4f831789d9224C72c785102334b4'
}

const lockedMasterChefMANGA1 = {
  97: '0x8EAEF04CB23F827c6EB03B931509924D40E6C541',
  56: '0x79BE3A374f854731C11431Fd8D08ADfDEDc61a8C'
}

const lockedMasterChefMANGA2 = {
  97: '0xE9B8F6d4837711e9e23792E80886f66B17c665c5',
  56: '0x402b3F5f2810287E55b47a17b70Ce566e6686D92'
}

const lockedMasterChefMANGA3 = {
  97: '0xE9B8F6d4837711e9e23792E80886f66B17c665c5',
  56: '0x5d6fb65d2beF367f24451BAea286a81D7B6E25EB'
}

const lockedMasterChefMANGA4 = {
  97: '0xE9B8F6d4837711e9e23792E80886f66B17c665c5',
  56: '0xD7a8ca37749471abd56E1af09Fd5990d59003E43'
}

const lockedMasterChefMANGA5 = {
  97: '0xE9B8F6d4837711e9e23792E80886f66B17c665c5',
  56: '0xb0CAa7125c8f5EcB9416583c5b39bBe08d5f6306'
}

const lockedMasterChefMANGA6 = {
  97: '0xE9B8F6d4837711e9e23792E80886f66B17c665c5',
  56: '0x0EFc3292d7AF032e1919560BA6B501d885cEE385'
}

// @todo contract farm
const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: '$MANGA-BUSD LP',
    lpAddresses: {
      97: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7',
      56: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7'
    },
    token: tokens.manga,
    quoteToken: tokens.busd,
    masterChefContract: lockedMasterChefMANGA1,
    earnToken: tokens.manga,
    openAt: 1627783200000,
    beforeOpenPerBlock: '0',
    isLocked: true
  },
  {
    pid: 0,
    lpSymbol: '$MANGA-BUSD LP',
    lpAddresses: {
      97: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7',
      56: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7'
    },
    token: tokens.manga,
    quoteToken: tokens.busd,
    masterChefContract: lockedMasterChefMANGA2,
    earnToken: tokens.manga,
    openAt: 1627783200000,
    beforeOpenPerBlock: '0',
    isLocked: true
  },
  {
    pid: 0,
    lpSymbol: '$MANGA-BUSD LP',
    lpAddresses: {
      97: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7',
      56: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7'
    },
    token: tokens.manga,
    quoteToken: tokens.busd,
    masterChefContract: lockedMasterChefMANGA3,
    earnToken: tokens.manga,
    openAt: 1627783200000,
    beforeOpenPerBlock: '0',
    isLocked: true
  },
  {
    pid: 0,
    lpSymbol: '$MANGA-BUSD LP',
    lpAddresses: {
      97: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7',
      56: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7'
    },
    token: tokens.manga,
    quoteToken: tokens.busd,
    masterChefContract: lockedMasterChefMANGA4,
    earnToken: tokens.manga,
    openAt: 1627783200000,
    beforeOpenPerBlock: '0',
    isLocked: true
  },
  {
    pid: 0,
    lpSymbol: '$MANGA-BUSD LP',
    lpAddresses: {
      97: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7',
      56: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7'
    },
    token: tokens.manga,
    quoteToken: tokens.busd,
    masterChefContract: lockedMasterChefMANGA5,
    earnToken: tokens.manga,
    openAt: 1627783200000,
    beforeOpenPerBlock: '0',
    isLocked: true
  },
  {
    pid: 0,
    lpSymbol: '$MANGA-BUSD LP',
    lpAddresses: {
      97: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7',
      56: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7'
    },
    token: tokens.manga,
    quoteToken: tokens.busd,
    masterChefContract: lockedMasterChefMANGA6,
    earnToken: tokens.manga,
    openAt: 1627783200000,
    beforeOpenPerBlock: '0',
    isLocked: true
  },
  {
    pid: 0,
    lpSymbol: '$MANGA-BUSD LP',
    lpAddresses: {
      97: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7',
      56: '0xce23ac8d784e99853f9940a1fc5bba303eec81f7'
    },
    token: tokens.manga,
    quoteToken: tokens.busd,
    masterChefContract: masterChefMANGA,
    earnToken: tokens.manga,
    openAt: 1627783200000,
    beforeOpenPerBlock: '0'
  }
  // {
  //   pid: 0,
  //   lpSymbol: '$MANGA',
  //   lpAddresses: tokens.manga.address,
  //   token: tokens.manga,
  //   quoteToken: tokens.manga,
  //   masterChefContract: lockedMasterChefMANGA1,
  //   earnToken: tokens.manga,
  //   openAt: 1627783200000,
  //   beforeOpenPerBlock: '0',
  //   isLocked: true
  // },
  // {
  //   pid: 0,
  //   lpSymbol: '$MANGA',
  //   lpAddresses: tokens.manga.address,
  //   token: tokens.manga,
  //   quoteToken: tokens.manga,
  //   masterChefContract: lockedMasterChefMANGA2,
  //   earnToken: tokens.manga,
  //   openAt: 1627783200000,
  //   beforeOpenPerBlock: '0',
  //   isLocked: true
  // },
  // {
  //   pid: 0,
  //   lpSymbol: '$MANGA',
  //   lpAddresses: tokens.manga.address,
  //   token: tokens.manga,
  //   quoteToken: tokens.manga,
  //   masterChefContract: masterChefMANGA,
  //   earnToken: tokens.manga,
  //   openAt: 1627783200000,
  //   beforeOpenPerBlock: '0'
  // }
]

export default farms
