import React from 'react'

export interface SectionBannerProps {
  title: string
  description: string
  bannerImageUrl: string
}

const SectionBanner: React.FC<SectionBannerProps> = ({
  title,
  description,
  bannerImageUrl
}) => {
  return (
    <div className='section-banner'>
      <img className='section-banner-icon' src={bannerImageUrl} alt='banner' />
      <p className='section-banner-title'>{title}</p>
      <p className='section-banner-text'>{description}</p>
    </div>
  )
}

export default SectionBanner
