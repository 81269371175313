import { Percent } from '@pancakeswap/sdk'
import React from 'react'
import { warningSeverity } from 'utils/prices'
import { ONE_BIPS } from '../../../config/constants'
import { ErrorText } from './styleds'

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({
  priceImpact
}: {
  priceImpact?: Percent
}) {
  return (
    <ErrorText fontSize='14px' severity={warningSeverity(priceImpact)}>
      {priceImpact
        ? priceImpact.lessThan(ONE_BIPS)
          ? '<0.01%'
          : `${priceImpact.toFixed(2)}%`
        : '-'}
    </ErrorText>
  )
}
