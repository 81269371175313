import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector
} from '@web3-react/walletconnect-connector'
import { ConnectorNames, connectorLocalStorageKey } from '@trophee-libs/uikit'
import Cookies from 'js-cookie'

import { connectorsByName } from 'utils/web3React'
import { setupNetwork } from 'utils/wallet'
import useToast from 'hooks/useToast'
import { profileClear } from 'state/profile'
import { useAppDispatch } from 'state'

declare global {
  interface Window {
    kardiachain: any
    kaiAccount: any
  }
}

const useAuth = () => {
  const dispatch = useAppDispatch()
  const { activate, deactivate } = useWeb3React()
  const { toastError } = useToast()

  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]
    if (connector) {
      activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork()
          if (hasSetup) {
            activate(connector)

            Cookies.set(connectorLocalStorageKey, connectorID, {
              domain: '.trophee.xyz'
            })
          }
        } else {
          Cookies.remove(connectorLocalStorageKey, {
            domain: '.trophee.xyz'
          })
          if (
            error instanceof NoEthereumProviderError ||
            error instanceof NoBscProviderError
          ) {
            toastError('Provider Error', 'No provider was found')
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            toastError(
              'Authorization Error',
              'Please authorize to access your account'
            )
          } else {
            toastError(error.name, error.message)
          }
        }
      })

      Cookies.set(connectorLocalStorageKey, connectorID, {
        domain: '.trophee.xyz'
      })
    } else {
      toastError("Can't find connector", 'The connector config is wrong')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(() => {
    dispatch(profileClear())
    deactivate()
    Cookies.remove(connectorLocalStorageKey, {
      domain: '.trophee.xyz'
    })
  }, [deactivate, dispatch])

  return { login, logout }
}

export default useAuth
