/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PriceApiResponse, PriceApiThunk, PriceState } from 'state/types'
import BigNumber from 'bignumber.js'

import multicall from 'utils/multicall'
import erc20 from 'config/abi/erc20.json'
import tokens from 'config/constants/tokens'
import { getAddress } from './../../utils/addressHelpers'

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  data: {}
}

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>(
  'prices/fetch',
  async () => {
    const response = await fetch(
      'https://us-central1-trophee-support-prod.cloudfunctions.net/v2/tokens'
    )

    const fetchMANGAPriceByPairs = async () => {
      const BUSD = getAddress(tokens.busd.address)
      const MANGA = getAddress(tokens.manga.address)
      const pairs = '0xce23ac8d784e99853f9940a1fc5bba303eec81f7' // MANGA - BUSD
      const calls = [
        {
          address: MANGA,
          name: 'balanceOf',
          params: [pairs]
        },
        {
          address: BUSD,
          name: 'balanceOf',
          params: [pairs] // MANGA - BUSD
        }
      ]
      const [mangaBal, busdBal] = await multicall(erc20, calls)

      return new BigNumber(busdBal)
        .div(new BigNumber(10).pow(tokens.busd.decimals))
        .div(
          new BigNumber(mangaBal).div(
            new BigNumber(10).pow(tokens.manga.decimals)
          )
        )
        .toNumber()
    }
    const mangaPrice = await fetchMANGAPriceByPairs()

    const data = { data: null }
    const data2 = (await response.json()) as PriceApiResponse
    data.data = {
      ...data2.data
    }
    // Return normalized token names
    const prices = {
      updated_at: new Date().getTime().toString(),
      data: Object.keys(data.data).reduce(
        (accum, token) => {
          return {
            ...accum,
            [token.toLowerCase()]: parseFloat(data.data[token].price)
          }
        },
        {
          [getAddress(tokens.manga.address).toLowerCase()]: mangaPrice
        }
      )
    }

    return prices
  }
)

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPrices.pending, state => {
      state.isLoading = true
    })
    builder.addCase(
      fetchPrices.fulfilled,
      (state, action: PayloadAction<PriceApiThunk>) => {
        state.isLoading = false
        state.lastUpdated = action.payload.updated_at
        state.data = action.payload.data
      }
    )
  }
})

export default pricesSlice.reducer
