/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatisticsState } from '../types'
import { getAddress } from '../../utils/addressHelpers'
import tokens from '../../config/constants/tokens'
import multicall from '../../utils/multicall'
import erc20 from '../../config/abi/erc20.json'
import { getBalanceNumber } from '../../utils/formatBalance'

const initialState: StatisticsState = {
  burned: 0,
  locked: 0,
  locked10: 0,
  distributed: 0,
  circulation: 0,
  users: 0,
  usersBep20: 0,
  lockedBridge: 0
}

export const statisticsSlice = createSlice({
  name: 'Block',
  initialState,
  reducers: {
    setStatistics: (state, action: PayloadAction<Partial<StatisticsState>>) => {
      return {
        ...initialState,
        ...state,
        ...action.payload
      }
    }
  }
})

// Actions
export const { setStatistics } = statisticsSlice.actions

export default statisticsSlice.reducer

export const fetchStatisticAsync = () => async dispatch => {
  const results = await Promise.all([
    fetch(
      `https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68&page=1&offset=100000&apikey=ITVBRJR178CWUWF73XR94YGC75GYDKDMA6`
    )
  ])

  const data = await results[0].json()

  dispatch(
    setStatistics({
      usersBep20: data.result.length
    })
  )
}
