import React from 'react'

const Loader: React.FC = () => {
  return (
    <div className='page-loader-indicator loader-bars mt-4'>
      <div className='loader-bar'></div>
      <div className='loader-bar'></div>
      <div className='loader-bar'></div>
      <div className='loader-bar'></div>
      <div className='loader-bar'></div>
      <div className='loader-bar'></div>
      <div className='loader-bar'></div>
      <div className='loader-bar'></div>
    </div>
  )
}

export default Loader
