import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import oldMasterchefABI from 'config/abi/old_masterchef.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { farmsConfig } from 'config/constants'

export const fetchFarmUserAllowances = async (
  account: string,
  farmsToFetch: FarmConfig[]
) => {
  const calls = farmsToFetch.map(farm => {
    const masterChefAddress = getMasterChefAddress(farm.masterChefContract)
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'allowance',
      params: [account, masterChefAddress]
    }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map(lpBalance => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (
  account: string,
  farmsToFetch: FarmConfig[]
) => {
  const calls = farmsToFetch.map(farm => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account]
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map(tokenBalance => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (
  account: string,
  farmsToFetch: FarmConfig[]
) => {
  const nonLockedFarmCalls = []
  const calls = farmsToFetch
    .map((farm, index) => {
      const masterChefAddress = getMasterChefAddress(farm.masterChefContract)
      if (!farm.isLocked) {
        nonLockedFarmCalls.push({
          call: {
            address: masterChefAddress,
            name: 'userInfo',
            params: [farm.pid, account]
          },
          index
        })

        return
      }
      return {
        address: masterChefAddress,
        name: 'userInfo',
        params: [farm.pid, account]
      }
    })
    .filter(f => f)

  const [nonLockedRawStakedBalances, rawStakedBalances] = await Promise.all([
    multicall(
      oldMasterchefABI,
      nonLockedFarmCalls.map(c => c.call)
    ),
    multicall(masterchefABI, calls)
  ])
  const nonLockedRawStakedBalancesMapping = nonLockedFarmCalls.reduce(
    (acc, cur, index) => ({
      ...acc,
      [cur.index]: nonLockedRawStakedBalances[index]
    }),
    {}
  )

  let rawStakedIndex = 0
  const stakedBalances = farmsToFetch.map((_, index) => {
    if (nonLockedRawStakedBalancesMapping[index]) {
      return nonLockedRawStakedBalancesMapping[index]
    }

    rawStakedIndex++
    return rawStakedBalances[rawStakedIndex - 1]
  })

  const parsedStakedBalances = stakedBalances.map(
    stakedBalance =>
      stakedBalance.amount && new BigNumber(stakedBalance.amount._hex)?.toJSON()
  )

  return parsedStakedBalances
}

export const fetchFarmUserCanHarverts = async (
  account: string,
  farmsToFetch: FarmConfig[]
) => {
  const calls = farmsToFetch.map(farm => {
    const masterChefAddress = getMasterChefAddress(farm.masterChefContract)
    return {
      address: masterChefAddress,
      name: 'canHarvest',
      params: [farm.pid, account]
    }
  })

  const canHarverts = await multicall(masterchefABI, calls)

  return canHarverts
}

export const fetchFarmUserEarnings = async (
  account: string,
  farmsToFetch: FarmConfig[]
) => {
  const calls = farmsToFetch.map(farm => {
    const masterChefAddress = getMasterChefAddress(farm.masterChefContract)
    return {
      address: masterChefAddress,
      name: 'pendingBEP20Token',
      params: [farm.pid, account]
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)

  const parsedEarnings = rawEarnings.map(earnings => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}

export const fetchFarmUserinitBlock = async (
  account: string,
  farmsToFetch: FarmConfig[]
) => {
  const nonLockedFarmCalls = []
  const calls = farmsToFetch
    .map((farm, index) => {
      const masterChefAddress = getMasterChefAddress(farm.masterChefContract)
      if (!farm.isLocked) {
        nonLockedFarmCalls.push({
          call: {
            address: masterChefAddress,
            name: 'userInfo',
            params: [farm.pid, account]
          },
          index
        })

        return
      }

      return {
        address: masterChefAddress,
        name: 'userInfo',
        params: [farm.pid, account]
      }
    })
    .filter(f => f)

  const [nonLockedRawInitBlocks, rawInitBlocks] = await Promise.all([
    multicall(
      oldMasterchefABI,
      nonLockedFarmCalls.map(c => c.call)
    ),
    multicall(masterchefABI, calls)
  ])
  const nonLockedRawInitBlocksMapping = nonLockedFarmCalls.reduce(
    (acc, cur, index) => ({
      ...acc,
      [cur.index]: nonLockedRawInitBlocks[index]
    }),
    {}
  )

  let rawInitBlockIndex = 0
  const initBlocks = farmsToFetch.map((_, index) => {
    if (nonLockedRawInitBlocksMapping[index]) {
      return nonLockedRawInitBlocksMapping[index]
    }

    rawInitBlockIndex++
    return rawInitBlocks[rawInitBlockIndex - 1]
  })

  const parsedInitBlocks = initBlocks.map(
    rawInitBlock =>
      rawInitBlock.initBlock &&
      new BigNumber(rawInitBlock.initBlock._hex)?.toJSON()
  )

  return parsedInitBlocks
}
