// @contract
export default {
  masterChef: {
    97: '0x4b05E4A7667C4f831789d9224C72c785102334b4',
    56: '0x4b05E4A7667C4f831789d9224C72c785102334b4'
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95'
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    0: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c'
  },
  lottery: {
    97: '0x1f703bAc064400a4E594f05d249A997509f3925D',
    56: '0x1f703bAc064400a4E594f05d249A997509f3925D'
  },
  lotteryNFT: {
    97: '0x491589598a53a928BcB724f3a748AcaA1e6695e5',
    56: '0x491589598a53a928BcB724f3a748AcaA1e6695e5'
  },
  multiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412'
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A'
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E'
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148'
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: ''
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3'
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C'
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97'
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F'
  },
  cakeVault: {
    56: '0xF981cF6DC8831Fe6D62E93e682eA40605953C2EF',
    97: '0xF981cF6DC8831Fe6D62E93e682eA40605953C2EF'
  },
  predictions: {
    56: '0x516ffd7D1e0Ca40b1879935B2De87cb20Fc1124b',
    97: ''
  },
  referrals: {
    56: '0x71B4DD5cc51246aBBf6610139D847ff7fDF69FA5',
    97: '0xb05f2D00DAA3D5289320aD8d59a31b75eED32D06'
  }
}
